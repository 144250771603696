<template>
  <PDialog
    classes="w-1/3"
    :title="$tk('PReturnDialog.Return')"
    @close="$emit('close')"
  >

    <PDialogContent>

      <PRadio
        :value="shouldReturn === false"
        @input="shouldReturn = false"
        :label="$tk('PReturnDialog.ShallNotBeReturned')"
      />

      <PRadio
        :value="shouldReturn === true"
        @input="shouldReturn = true"
        :label="$tk('PReturnDialog.ShallBeReturned')"
      />

      <div class="flex flex-col mt-6 space-y-4" v-if="shouldReturn">

        <PDatePicker
          class="w-32"
          :label="$tk('PReturnDialog.ReturnDate')"
          :min="minDate"
          :max="maxDate"
          v-model="returnDate"
        />

        <PText
          :label="$tk('PReturnDialog.ReturnFrom')"
        >
          {{ group.locationNameTo }} ({{ group.locationIdTo }})
        </PText>

        <PText
          :label="$tk('PReturnDialog.ReturnTo')"
        >
          {{ group.locationNameFrom }} ({{ group.locationIdFrom }})
        </PText>

      </div>

    </PDialogContent>
    <PDialogActions>

      <PButton
        color="secondary"
        @click="onCancel"
        :disabled="isSubmitting"
      >
        <span v-html="$tk('Common.Actions.Cancel')"></span>
      </PButton>

      <PButton
        class="ml-2"
        color="primary"
        @click="onSubmit"
        :disabled="!canSubmit"
        :loading="isSubmitting"
      >
        <span v-html="$tk('Common.Actions.Send')"></span>
      </PButton>

    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/utilities/http"

import {
  filter,
  forEach,
  minBy,
  some
} from "lodash"

import {
  add,
  format
} from "date-fns"

import {
  mapGetters
} from "vuex"

export default {

  data () {
    return {
      returnDate: format(new Date(), "yyyy-MM-dd"),
      shouldReturn: false,
      isLoading: false,
      isSubmitting: false
    }
  },

  props: {
    group: {
      type: Object,
      required: true
    }
  },

  computed: {

    ...mapGetters([
      "user",
      "location"
    ]),

    minDate () {
      return format(new Date(), "yyyy-MM-dd")
    },

    selectedOrders () {
      return filter(this.group.orders, order => {
        return some(this.group.selected, selected => selected === order.id)
      })
    },

    maxDate () {
      const minReturnDays = minBy(this.selectedOrders, "returnDays").returnDays
      return format(add(new Date(), { days: minReturnDays }), "yyyy-MM-dd")
    },

    canSubmit () {
      return !this.shouldReturn ||
             (this.shouldReturn && !!this.returnDate)
    }

  },

  methods: {

    onCancel () {
      this.$emit("close")
    },

    async onSubmit () {

      this.isSubmitting = true

      if (this.shouldReturn) {
        try {
          await http.post("OrderCreateReturn", {
            locationIdTo: this.group.locationIdFrom,
            orderIds: this.group.selected,
            returnDate: this.returnDate
          })
        } catch (error) {
          this.handleError(error)
        }
      } else {
        try {
          let promises = []
          forEach(this.group.selected, id => {
            promises.push(http.post("OrderCancelReturnable", {
              orderId: id
            }))
          })
          await Promise.all(promises)
        } catch (error) {
          this.handleError(error)
        }
      }

      this.isSubmitting = false

      this.$emit("close")
    },

    handleError (error) {
      this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })
    }

  }

}

</script>
